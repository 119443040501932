<style scoped></style>

<template>
    <div style="padding: 10px 20px">
        <el-form ref="form" status-icon label-width="90px" label-position="left">
            <el-form-item prop="phone" label="修改章节：">
                <el-input v-model="chapter.title" maxlength="20" placeholder="请输入章节标题" show-word-limit type="text" />
            </el-form-item>
        </el-form>
        <el-button type="primary" @click="editChapter()" style="width: 100%">保存</el-button>
    </div>
</template>

<script>
import { apiEditChapter } from "@/api/admin/chapter.js";

export default {
    data() {
        return {
            chapter: {
                id: "",
                title: ""
            }
        };
    },
    methods: {
        getChapter() {
            this.chapter.id = this.$route.query.id;
            this.chapter.title = this.$route.query.title;
        },
        async editChapter() {
            let formData = new FormData();
            //去除首尾空格
            this.chapter.title = this.chapter.title.trim();
            if (this.chapter.title == "") {
                this.$message({ message: "请输入标题", type: "error" });
                return;
            }
            formData.append("token", this.$store.state.token);
            formData.append("id", this.chapter.id);
            formData.append("title", this.chapter.title);

            //调用接口
            const response = await apiEditChapter(formData);
            if (response.data.code === 200) {
                //调用父页面关闭layer
                window.parent.postMessage({ message: "修改课题章节成功", code: "200" }, "*");
            } else {
                this.$message({ message: response.data.message, type: "error" });
            }
        }
    },
    mounted() {
        this.getChapter();
    }
};
</script>
